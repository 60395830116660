export const articles = [
    {
        title: `Od začetka do danes`,
        content: `Začetki projekta segajo v leto 2009, ko je bila v Prigorici postavljena prva vremenska postaja v Ribniški dolini, katere podatke je bilo možno v realnem času spremljati na spletu. Leta 2018 je spletna stran zaradi pogostih izpadov in precejšnjih stroškov vzdrževanja prenehala z delovanjem, vzporedno pa so že tekla razmišljanja o postavitvi nove vremenske postaje. Tako meteorološke meritve v Prigorici od oktobra 2019 potekajo s profesionalno postajo HOBO RX3000, za katero je značilno bolj avtonomno delovanje in zanesljivejši prenos podatkov na splet. Ker pa je občina Ribnica reliefno zelo razgibana in se posledično lahko vremenske razmere spreminjajo že na manjših razdaljah, sem se v letošnjem letu odločil za projekt vzpostavitve mreže vremenskih postaj, ki sem ga razširil še na sosednjo občino Loški Potok. Tako so bile letos na novo postavljene kar tri postaje.&`,
    },
    {
        title: `Vreme zanima vse`,
        content: `Že januarja sem z meteorološkimi meritvami začel v vasi Retje, kar je že v prvih dneh pritegnilo veliko pozornosti ne samo domačinov, ampak tudi širše javnosti in medijev, saj so bile tukaj izmerjene precej nižje temperature kot na Babnem polju, ki zaradi dolgoletnih meritev velja za naše najbolj znano mrazišče. Februarja je sledila postavitev vremenske postaje v Gašpinovem v Slemenih, novembra pa še na Sveti Ani, ki med Ribničani velja za priljubljeno izletniško točko.&
        Spletna stran, na kateri se nahajate, je nastala v želji, da lahko na enem mestu dostopate do podatkov iz vseh meteoroloških postaj v občini Ribnica in Loški Potok. Svojim postajam sem dodal še podatke iz vremenske postaje Ribnica, katere lastnik je komunala Ribnica, in podatke iz postaje Hrib, ki je postavljena pri tamkajšnji osnovni šoli.& 
        Poleg aktualnih podatkov lahko na strani preverite še vremensko napoved tako za Ribnico kot za Loški Potok ter ostale zanimivosti o vremenu v tem delu Slovenije. Podatki bodo zelo dragoceni tudi v primeru ekstremnih vremenskih dogodkov, saj bom z njihovo pomočjo lahko naredil podrobne analize, ki bodo prispevale k boljšemu razumevanju vzrokov nastanka vremenskih pojavov.&
        `
    },
    {
        title: `Toliko za začetek!`,
        content: `Spletno stran bom sproti nadgrajeval, hkrati pa že razmišljam o širitvi mreže meteoroloških postaj tudi na občine Kočevje, Sodražica in Velike Lašče. Portal vreme-podnebje.si je nastal kot posledica velikega interesa in spodbud ljudi, ki so želeli več vremenskih podatkov. Tudi v nadaljevanju bom vesel vaših predlogov!&
        Brez naših sponzorjev ne bi mogel postaviti niti ene vremenske postaje, kaj šele postaviti in vzdrževati portala s tako vsebino. Za vsako novico ali interpretacijo vremenskega dogajanja, ki jo boste lahko spremljali na tej strani, stoji ogromno znanja in analize, kar vzame veliko časa. Zato se vsem dosedanjim sponzorjem iskreno zahvaljujem za zaupanje. Vse, ki bi to radi šele postali, pa vabim, da mi pišete na elektronski naslov rok.nosan@vreme-podnebje.si.&
        Rok Nosan&`
    }
]

export default articles;