<template>
  <div>
      <h2 class="text-center fw-bolder mt-4 title justify-content-between test">Vreme 24/7 – vse o vremenu od Kolpe do Barja in širše</h2>
      <AboutPage />
  </div>
</template>

<script>
import AboutPage from '../components/About/AboutPage.vue'

export default {
    name: 'About',
    components: {
        AboutPage
    },
}
</script>

<style scoped>
.title {
    font-size: 37px;
}

.test {
  text-align: justify;
}
</style>