<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12 col-md-12">
        <h6>{{ article.title }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <div class=""
          v-for="(paragraph, i) in CreateParagraphs(article.content)"
          :key="paragraph"
        >
          <div v-if="i == 0" class="test justify-content-between">
            {{ paragraph }}
          </div>
          <div v-else><br />{{ paragraph }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        {{ article.signature }}
      </div>
    </div>
  </div>
</template>

<script>
import CreateParagraphs from '../../Data/News/Editor';

export default {
  name: "AboutArticle",
  methods: {
      CreateParagraphs
  },
  props: {
    article: Object,
  },
};
</script>

<style scoped>
.test {
  text-align: justify;
}
</style>