<template>
  <div class="container">
    <div class="row">
      <div
        class="col-12 offset-md-1 col-md-10"
        v-for="article in articles"
        :key="article"
      >
        <AboutArticle :article="article" />
      </div>
      <div
        class="col-12 offset-md-1 col-md-11 test justify-content-between mt-4"
      >
        <div class="container mt-3">
          <div class="row">
            <div class="col-12 col-md-12">
              <h6 class="d-inline">Izdelava in oblikovanje spletne strani: </h6>
              <span>Amadej Krepek</span> <br />
              <h6 class="mt-3 d-inline">Zajem in obdelava podatkov: </h6>
              <div class="d-inline">Pileus, Iztok Miklavčič s. p.</div> <br />   
              <h6 class="mt-3 d-inline">Avtorja naslovnih fotografij: </h6>
              <div class="d-inline">Daniel Vincek, Rok Nosan</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutArticle from "./AboutArticle.vue";
import Articles from "../../Utils/About/About";

export default {
  name: "AboutPage",
  data() {
    return {
      articles: Articles,
    };
  },
  components: {
    AboutArticle,
  },
};
</script>

<style>
</style>